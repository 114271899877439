   .backToTopContainer {
    position: fixed;
    bottom: 4rem; 
    right: 1rem;
    z-index: 10000;
  }
  
  .backToTopButton {
    padding: 0.5rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: #2d3748;
    color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: none;
    transition: background-color 0.3s ease;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .backToTopButton:hover {
    background-color: #4a5568;
  }
  
  .backToTopButton:focus {
    ring: 2px solid #2d3748;
    ring-opacity: 50%;
  }
  
  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  @media (max-width: 768px) {
    .backToTopContainer {
      bottom: 0.5rem; 
      right: 0.5rem;
    }
  
  
    .bottomAtEnd {
      bottom: 3rem; 
    }
  }
  