.list {
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-right: 0.7rem;
}

.links {
  background: none;
  border: none;
  padding: 0.5rem;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
  transition: transform 0.3s ease;
  /* color: #627580; */
  color: var(--text-day-mode);
  margin-top: 0;
}

.linksNight{
  color: var(--text-night-mode);
}

.links:hover {
  text-decoration: underline;
  /* transform: scale(1.1); */
}

.linksNight:hover {
  text-decoration: underline;
  color: var(--text-night-mode);
}

.languageDropdown {
  border: 0.5px solid #374957;
  outline: none;
  padding: 0.2rem;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  color: #374957;
}

.option {
  font-size: 1.1rem;
}
.hamburger img {
  width: 2rem;
  height: auto;
  padding: 1.5rem;
  display: none;
}

@media only screen and (max-width: 768px) {
  .hamburger img {
    display: block;
  }

  .mobileLogo {
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
  }

  .hamburger {
    cursor: pointer;
  }
  .list {
    position: relative;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    backdrop-filter: blur(32px);
    transform: translateX(-100%);
    transition: transform 0.5s ease, opacity 0.5s ease;
    opacity: 0;
    pointer-events: none;
    z-index: 1000;
    padding: 2rem;
    margin: 0;
    border-radius: 0 0 0.5rem 0;
    height: auto; /* Allow the nav to grow with content */
    max-height: 100vh; /* Prevent the list from exceeding the viewport */
    overflow-y: auto; /* Enable vertical scrolling */
    box-sizing: border-box;
  }

  .show {
    transform: translateY(0%);
    opacity: 1;
    pointer-events: auto;
  }

  .links {
    padding: 1rem;
    display: inline;
  }

  
}

