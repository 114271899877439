.fabContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fabMenu {
  position: relative;
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;
  max-height: 0;
  overflow: hidden; 
  transition: max-height 0.5s ease-in-out;
}

.fabMenu.show {
  max-height: 300px; 
}

.fabItem {
  opacity: 0;
  transform: translateY(-10px); 
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.fabItem.show {
  opacity: 1;
  transform: translateY(0);
}

@media (min-width: 768px) {
  .fabMenu {
    position: fixed;
    top: 4rem;
    right: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease;
    opacity: 0;
  }

  .fabMenu.show {
    max-height: 300px; 
    opacity: 1; 
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease;
  }
}


/* @media only screen 
  and (min-device-width: 820px) 
  and (max-device-width: 1180px) 
  and (-webkit-min-device-pixel-ratio: 2) 
  and (orientation: portrait)
  and (aspect-ratio: 3/4) {
  .fabMenu {
    position: relative; 
    bottom: initial;
    right: initial; 
    max-height: 0; 
    overflow: hidden; 
    transition: max-height 0.5s ease-in-out;
    top: 1rem;
  }

  .fabMenu.show {
    max-height: 300px;
  }
} */


@media (max-width: 768px) {
  .fabMenu {
    position: relative;
    padding: 1.2rem;
    max-height: 0; 
    overflow: hidden; 
    transition: max-height 0.5s ease-in-out; 
  }

  .fabMenu.show {
    max-height: 300px;
    top:1rem;
  }
}
