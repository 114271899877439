.Link {
  cursor: pointer;
  text-decoration: none;
  padding: 0.7rem 1rem;
  border-radius: 0.5rem;
  font-weight: 600;
  font-size: 1rem;
  transition: transform 0.3s ease, background-color 0.3s ease,
    box-shadow 0.3s ease, color 0.3s ease;
  background-color: rgba(255, 255, 255, 0.2);
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
  /* position: relative; */
}

.Link:hover {
  background: #3747d6;
  color: #fff;
  transform: translateY(-3px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
  border-color: #3747d6;
}

.lightTheme {
  color: #3A4A54;
}

.darkTheme {
  color: var(--text-night-mode);
}

button{
  border:none;
}