.headerContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: rgba(255, 255, 255, 0.9); */
  /* position: fixed; */
  top: 0;
  /* width: 100%; */
  height: 4rem;
  z-index: 1000;
  margin: 0.5rem ;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); */
}

.languageTogg {
  width: 2rem;
  height: 2rem;
  border: 2px solid #374957;
  cursor: pointer;
  transition: transform 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.languageTogg:hover {
  transform: scale(1.2);
}

.langThemeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}



/* .logo {
  width: 4rem;
  height: 4rem;
 
} */

.logo{
  text-decoration: none;
  color: var(--text-color); /* Definește culoarea în CSS vars pentru a schimba între Dark și Light Mode */
  font-family: 'Arial', sans-serif;
  padding:1rem;
  font-size: 1.2rem;
  font-weight: 600;
}

.logoCont {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media only screen and (max-width: 800px) {
}
