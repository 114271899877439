/* * {
  box-sizing: border-box;
} */


:root {
  --text-night-mode: #e0e6ea;
  --bg-night-mode: #000;
  --text-day-mode: #3a4a54;
  --bg-day-mode: #e0e0e0;
}

body {
  margin: 0;
  padding: 0;
  font-size: 1.2rem;
  font-weight: 500;
  font-family: "Arial", sans-serif;
}

.darkTheme {
  background-color: var(--bg-night-mode);
  color: var(--text-night-mode);
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-attachment: fixed;
}

.lightTheme {
  /* background-color: var(--bg-day-mode); */
  background: linear-gradient(135deg, #ffffff, #f0f8ff, #d1e7f5, #b4d4ea);
  background-size: 400% 400%;
  animation: gradientBG 15s ease infinite;
  color: var(--text-day-mode);
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background-attachment: fixed;
  /* overflow-x: hidden;  */
}

@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media (max-width: 767px) {
  body {
    background-attachment: scroll;
  }
}
