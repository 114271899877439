.toggleBtn {
  width: 2rem;
  height: 2rem;
  margin: 0.5rem;
  cursor: pointer;
}

@media only screen and (max-width: 800px) {
  .toggleBtn {
    width: 1.5rem;
    height: 1.5rem;
  }
}
